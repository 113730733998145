<template>
  <div class="xa_foot">
      <div class="main">
          <div class="xa_foot_a">
              <p>Copyright&nbsp;©&nbsp;2023&nbsp;&nbsp;&nbsp;&nbsp;航空科技成果线上展厅所有</p>
              <!-- <p>
                  <a href="//beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer nofollow">津ICP备05001145号-6</a>
                  <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12010302002176" style="display:inline-block;text-decoration:none;height:20px;line-height:20px; padding-left: 30px;"><img src="https://image.tjkjcg.com/market/yun/tianjin/www/images/this/icon_ba.png" style="display:inline-block; padding-right: 5px; vertical-align: middle;">津公网安备 12010302002176号</a>
              </p> -->
              <p>技术支持：武汉迪科巨图科技有限公司</p>
          </div>
          <div class="xa_foot_c">
              <p class="hur2">联系电话：{{dataInfo.asTel}}</p>
              <p class="hur1">邮箱:{{dataInfo.asEmail}}</p>
              <p class="hur3">地址：{{dataInfo.asAddress}}</p>
          </div>
      </div>

  </div>
</template>

<script>
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
export default {

  data() {
    return {
       dataInfo:{},
     }
  },
  created() {

  },
  mounted() {
    this.getInfo()
  },
  methods: {
    //获取列表
    getInfo() {
      let data = {
        asId: 1,
      }
      postRequest(api.aboutUsId, data).then(res => {
         this.dataInfo = res.data
      })

    },
  },

}
</script>

<style lang="less" scoped>
  .xa_foot {
      background: #212630;
      padding: 20px 0;
      width: 100%;
      }
  .main {
      width: 90%;
      margin: auto;
      font-size: 12px;
      text-align: left;
      display: flex;
      justify-content: space-between;
  }
  .xa_foot_a {
      width: 460px;
      border-right: 1px #383c45 solid;
  }
  .xa_foot p {
      font-size: 14px;
      line-height: 30px;
      color: #999;
      text-align: left;
  }
  .xa_foot_c {
      width: 620px;
  }
  .xa_foot * {
    color: #fff;
    font-family: "Microsoft YaHei";
}

.xa_foot p a {
    margin: 0;
    padding: 0;
    color: #999;
}
</style>
