<template>
  <div>
  <div class="contentv">

    <div class="navtab">
      <div class="loginmain">
        <img class="logoimg" src="../assets/images/logo.png"/>
      </div>
      <div class="menudiv">
        <el-menu ref="menu" :default-active="activeIndex" mode="horizontal" :router="true" menu-trigger="click"
            :unique-opened="true" text-color='#000' active-text-color="#306dac">
            <el-menu-item index="/home">
              <template>
                <span>首页</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/resultsinfo">
              <template>
                <span>科技成果</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/parkinfo">
              <template>
                <span>园企产品</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/serviceinfo">
              <template>
                <span>科技服务</span>
              </template>
            </el-menu-item>

            <el-submenu index="/prelease">
                <template slot="title">需求发布</template>
                <el-menu-item >
                  <span @click="toneed">需求发布</span>
                  </el-menu-item>
                <el-menu-item index="/prelease">需求查询</el-menu-item>
              </el-submenu>

            <el-submenu index="/team">
              <template slot="title">技术团队</template>
              <el-menu-item index="/team">概况信息</el-menu-item>
              <el-menu-item index="/teamExpert">特聘专家</el-menu-item>
              <el-menu-item index="/organList">科创机构</el-menu-item>
              <el-menu-item index="/teamList">创新团队</el-menu-item>
              <el-menu-item index="/parkList">园区企业</el-menu-item>
            </el-submenu>
            <el-submenu index="/notice">
              <template slot="title">时讯政策</template>
              <el-menu-item index="/notice">通知公告</el-menu-item>
              <el-menu-item index="/news">成果新闻</el-menu-item>
              <el-menu-item index="/aboutus">政策新闻</el-menu-item>
            </el-submenu>

            <el-submenu index="/about">
              <template slot="title">关于我们</template>
              <el-menu-item index="/about">关于我们</el-menu-item>
              <el-menu-item index="/roomprofile">领域简介</el-menu-item>
              <el-menu-item index="/aboutmessage">合作留言</el-menu-item>
              <el-menu-item index="/aboutphone">联系方式</el-menu-item>
            </el-submenu>
            <el-submenu  v-if="isLogin==1" index="1">
              <template slot="title">{{username}}</template>
              <el-menu-item @click="tourl(1)">个人中心</el-menu-item>
            </el-submenu>

          </el-menu>
        <div class="rightinfo" v-if="isLogin==0">

             <div class="backinfo" @click="tologin(1)">
               登录
             </div>
            <!-- <div class="backinfo">
               |
             </div>
             <div class="backinfo" @click="tologin(2)">
               注册
             </div> -->

           </div>


      </div>

    </div>
  </div>
  <div style="height: 100px;"></div>
  </div>
</template>
<script>
  import local from '../utils/local'
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
  export default {

    data() {
      return {
        activeIndex: '',
        isLogin:'',
        username:'当前用户',
      }
    },
    created() {
         this.isLogin = sessionStorage.getItem('isLogin')?sessionStorage.getItem('isLogin'):0
         this.username = '当前用户'
    },

    methods: {
      //1会员中心2退出登陆
      tourl(type){
        if(type==1){
            window.location.href = api.link_url+'/manager.html'
        }else if(type==2){
             sessionStorage.setItem('isLogin',0)
             postRequest(api.logout, {}).then(res => {
                 this.$router.push({
                   path: '/home',
                   query: {},
                 });
             })
        }
      },
      //供需交流
      toneed(){
         if(!sessionStorage.getItem('isLogin')||sessionStorage.getItem('isLogin')==0){
            // window.location.href = api.link_url+'/login.html'
              this.$confirm('当前未登陆,是否前往登陆?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'info'
                }).then(() => {
                  // this.$message({
                  //   type: 'success',
                  //   message: '删除成功!'
                  // });
                  window.location.href = api.link_url+'/login.html'
                }).catch(() => {
                  // this.$message({
                  //   type: 'info',
                  //   message: '已取消删除'
                  // });
                });
         }else if(sessionStorage.getItem('isLogin')==1){
            window.location.href = api.link_url+'/manager.html'
         }
      },

      //登陆页面
      tologin(type) {
        if (type == 1) {
          if(!sessionStorage.getItem('isLogin')||sessionStorage.getItem('isLogin')==0){
             window.location.href = api.link_url+'/login.html'
          }else if(sessionStorage.getItem('isLogin')==1){
             window.location.href = api.link_url + '/manager.html'
          }
        } else {
          this.$router.push({
            path: '/register',
            query: {},
          });
        }

      },
      initMenuActive(routePath) {
        this.activeIndex = routePath
      },
    },
    watch: {
      // 监听路由变化
      '$route.path': {
        handler(routePath) {
          this.initMenuActive(routePath)
        },
        immediate: true
      },
    }
  }
</script>

<style lang="less" scoped>
  .htext:hover{
    color: #f00;
    text-decoration: underline;
    cursor: pointer;
  }
  .contentv{
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999;
    height: 100px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
  }
  .el-menu--popup {
    width: 100px !important;
  }

  .el-menu-item {
    text-align: center;
  }
  .el-submenu /deep/ .el-submenu__title{
    font-size: 18px!important;
    font-weight: bold;
  }
  // .is-active{
  //   font-size: 16px;
  // }
  .header {
    width: 100%;
    height: 100px;
    position: relative;
  }

  .navtab {
    // background-color: #fff;
    // overflow-x: auto;
    // padding-bottom: 4px;
    // display: flex;
    //     justify-content: space-between;
    //     padding: 20px;
    // border-bottom: 1px solid #ddd;
    background-color: #fff;
        // overflow-x: auto;
        padding-bottom: 4px;
        display: flex;
        justify-content: space-around;
        padding: 20px 20px 9px 20px;
        /* border-bottom: 1px solid #ddd; */
        width: 90%;
        margin: 0 auto;
    span{
       font-size: 18px;
       font-weight: bold;
    }
    div{
      font-size: 16px;
    }
  }

  .el-menu {
    width: 100%;
    margin: auto;
    border-bottom: none !important;
    display: flex;
    justify-content: space-between;
    background: none!important;
  }



  .menu-item {
    background-color: none;
    width: 80%;
  }

  .main {
    width: 1180px;
    margin: auto;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

  }
  .loginmain {
    display: flex;
    align-items: center;
    background:#fff;
    margin-right: 20px;


    .logoimg {
     width: 280px;
         height: 70px;
      // border: 2px solid #bde5f9;
      // border-radius: 50%;
      // padding: 4px;

      // img {
      //   width: 36px;
      //   height: 36px;
      //   border: 2px solid #fff;
      //   padding: 4px;
      //   border-radius: 50%;

      // }
    }
    .centerdiv {
      font-size: 24px;
      font-weight: bold;
      color: #00A0E9;
      padding-left: 10px;
    }
  }

  .ctitle {
    height: 60px;
    width: 1180px;
    margin: auto;
    font-size: 14px;
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .infotext {
    line-height: 60px;
    color: #fff;
    a {
      color: #fff;
    }
  }

  .rightinfo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
  }

  .backinfo {
   color: #00428E;
    // background-color: #306dac;
    height: 30px;
    // width: 48px;
    line-height: 30px;
    border-radius: 6px;
    display: inline-block;
    text-align: center;
    margin-left: 4px;
    cursor: pointer;
    font-size: 24px;
  }
  .menudiv{
    display: flex;
    justify-content:flex-end;
  }
</style>
